import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ color: _ctx.color })
  }, [
    _createVNode(_component_el_icon, { size: "1em" }, {
      default: _withCtx(() => [
        _createVNode(_component_Warning)
      ]),
      _: 1
    }),
    _createElementVNode("span", null, _toDisplayString(_ctx.placeholder), 1)
  ], 4))
}